import React, { useState } from 'react'
import Loadable from 'react-loadable'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Section,
  SectionHeader,
  SectionContainer,
  SectionDescription,
  SectionContent
} from '../styles/styles'
import ImageGallery from '../components/gallery'
import RichTextRenderer from '../components/rich-text-renderer'

const LoadableCalendar = Loadable({
  loader: () => import('../components/recroom-calendar'),
  loading() {
    return <div>Loading...</div>
  }
})

const CalendarLegend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`

const BookButton = styled.a`
  display: inline-block;
  padding: 5px 10px;
  color: #fff;
  font-weight: bold;
  font-family: 'Roboto Condensed';
  text-transform: none;
  font-size: 1.4rem;
  background: #a3a569 0% 0% no-repeat padding-box;
  border-radius: 5px;
`

const AvailabilityButtonContainer = styled.div`
  display: flex;
  align-items: center;
  display: none;
`

const AvailabilityButton = styled.button`
  display: inline-block;
  padding: 5px 5px;
  color: #fff;
  font-family: 'Roboto';
  text-transform: none;
  font-size: 0.9rem;
  line-height: 1rem;
  background: none;
  border: none;
`

const AvailabilityIcon = styled.span`
  font-size: 2rem;
  color: rgba(163, 165, 105, 1);
  transform: ${props => (props.showCal ? 'rotate(90deg)' : 'rotate(-90deg)')};
`

const Testimonials = styled.div`
  column-count: 2;
  column-gap: 25px;
  @media (max-width: 900px) {
    column-count: 1;
  }
`

const Testimonial = styled.div`
  break-inside: avoid;
  margin-bottom: 20px;
`

const TestimonialName = styled.div`
  margin-bottom: 10px;
  color: #a3a569;
  font-size: 1.1rem;
  padding-bottom: 5px;
  border-bottom: solid 1px #b95f2f;
  font-weight: bold;
`

const TestimonialContent = styled.div``

const ReclamationRoom = ({ data }) => {
  const {
    title,
    galleryTitle,
    gallerySubtitle,
    galleryDescription,
    amenitiesSubtitle,
    amenities,
    galleryImages,
    backgroundImage,
    reclamationRoomInfo,
    bookUrl,
    testimonials
  } = data.contentfulReclamationRoomPage

  const [showCal, setShowCal] = useState(true)

  return (
    <Layout bgImage={backgroundImage}>
      <SEO title={title} />
      <h1>{title}</h1>
      <SectionHeader>{galleryTitle}</SectionHeader>
      <Section>
        <SectionContainer>
          <SectionDescription style={{ marginBottom: 20 }}>
            <h4>{gallerySubtitle}</h4>
            <p>{galleryDescription}</p>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <BookButton target="_blank" href={bookUrl}>
                Book your next event
              </BookButton>
              <AvailabilityButtonContainer>
                <AvailabilityButton onClick={() => setShowCal(!showCal)}>
                  {showCal ? 'Hide' : 'Show'}
                  <br />
                  Availability
                </AvailabilityButton>
                <AvailabilityIcon
                  className="fc-icon fc-icon-chevron-right"
                  showCal={showCal}
                  onClick={() => setShowCal(!showCal)}
                ></AvailabilityIcon>
              </AvailabilityButtonContainer>
            </div>
            {showCal && (
              <div style={{ marginBottom: 5 }}>
                <LoadableCalendar />
                <CalendarLegend>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        height: 15,
                        width: 15,
                        background: 'rgba(0, 0, 0, 0.75)'
                      }}
                    />
                    <div style={{ lineHeight: '2rem', marginLeft: 4 }}>
                      Not Available
                    </div>
                  </div>
                  <div style={{ width: 10 }} />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        height: 15,
                        width: 15,
                        background: 'rgba(75, 75, 75, 1)'
                      }}
                    />
                    <div style={{ lineHeight: '2rem', marginLeft: 4 }}>
                      Available
                    </div>
                  </div>
                </CalendarLegend>
              </div>
            )}
            <div
              style={{
                fontWeight: 'bold',
                color: '#fff',
                fontSize: '1.1rem',
                lineHeight: '2rem'
              }}
            >
              {amenitiesSubtitle}
            </div>
            {amenities.map(a => (
              <div>{a}</div>
            ))}
            <RichTextRenderer json={reclamationRoomInfo.json} />
            <div
              style={{
                marginTop: 25,
                maxWidth: '50%'
              }}
            >
              <a href="http://www.theknot.com/marketplace/cinder-block-brewery-north-kansas-city-mo-1089489?utm_source=vendor_website&utm_medium=banner&utm_term=0e301afa-5db6-4835-bc3c-a89000cd1bc8&utm_campaign=vendor_badge_assets">
                <Img
                  fluid={data.contentfulAsset.fluid}
                  alt={data.contentfulAsset.title}
                />
              </a>
            </div>
          </SectionDescription>
          <SectionContent>
            <ImageGallery images={galleryImages} />
          </SectionContent>
        </SectionContainer>
      </Section>
      <SectionHeader>Testimonials</SectionHeader>
      <Section>
        <Testimonials>
          {testimonials.map(t => (
            <Testimonial>
              <TestimonialName>{t.name}</TestimonialName>
              <TestimonialContent>{t.content.content}</TestimonialContent>
            </Testimonial>
          ))}
        </Testimonials>
      </Section>
    </Layout>
  )
}

export default ReclamationRoom

export const query = graphql`
  query RecRoomQuery {
    contentfulAsset(title: { eq: "As Seen on Knot" }) {
      title
      fluid {
        src
        srcSet
        sizes
        srcSetWebp
        srcWebp
        base64
        aspectRatio
      }
    }
    contentfulReclamationRoomPage {
      amenities
      amenitiesSubtitle
      backgroundImage {
        fluid(maxWidth: 1024) {
          src
          srcSet
          sizes
          srcSetWebp
          srcWebp
          base64
          aspectRatio
        }
      }
      galleryImages {
        fixed(width: 200) {
          width
          height
          src
          srcSet
        }
        fluid {
          src
          srcSet
          sizes
          srcSetWebp
          srcWebp
          base64
          aspectRatio
        }
      }
      galleryDescription
      gallerySubtitle
      galleryTitle
      reclamationRoomInfo {
        json
      }
      title
      testimonials {
        name
        content {
          content
        }
      }
      bookUrl
      backgroundImage {
        file {
          url
        }
      }
    }
  }
`
